html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 112.5%;
  font-weight: 300;
  background-color: #32414f;
  color: #333333;
}

@media (min-width: 1000px) {
  html {
    font-size: 125%;
  }
}

body {
  font-family: "Source Sans Pro", sans-serif;
  background-color: white;
}

* {
  box-sizing: border-box;
  line-height: 1.4;
}

*:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem #254b6d;
}

img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}
h1 {
  font-size: clamp(2rem, 16vmin, 7rem);
  line-height: 1;
}
h2 {
  font-size: clamp(1.5rem, 12vmin, 2.5rem);
  line-height: 1.1;
}
h3 {
  font-size: clamp(1.25rem, 8vmin, 2rem);
  line-height: 1.2;
}
h4 {
  font-size: clamp(1.1rem, 4vmin, 1.5rem);
  line-height: 1.3;
}
h5 {
  font-size: clamp(1rem, 2.5vmin, 1.25rem);
  line-height: 1.4;
}

a {
  color: #d24b7b;
}

*:focus {
  box-shadow: 0 0 0 0.2rem #ffbd66;
}
